@import './variables';
@import '~rfs/scss';

#work-slider {
    margin: 0;

    .work-slider {

        &-loader {
            opacity: 0;
        }

        &-bg {
            width: 100%;
            height: 100vh;
            position: fixed;
            display: flex;
            justify-content: center;
            align-items: center;
            left: 0;
            top: 0;

            &-img {
                margin: auto 3em;
                width: 60vw;
                opacity: 0;
                position: absolute;
                transition: opacity 0.3s ease;
            }

            &-img.active {
                opacity: 0.5;
            }

            &-img.active-click {
                opacity: 0.5;
            }
        }
    }
}

.carousel {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    cursor: grab;

    &.dragging {
        cursor: grabbing;
    }

    &--wrap {
        white-space: nowrap;
    }

    &--item {
        display: inline-block;
        width: 88vw;
        padding: 3vw;
        user-select: none;

        &:hover {

            .carousel--item--image {
                transform: scale(1.1) !important;
            }

            .carousel--item--text--number {
                border-color: $color-text;
            }
        }

        figure {
            position: relative;
            z-index: 1;
            display: block;
            height: 0;
            padding-bottom: 56.25%;
            overflow: hidden;

            img {
                position: absolute;
                z-index: 1;
                height: 100%;
                width: 100%;
                object-fit: cover;
                vertical-align: middle;
                transform-origin: center;
                transition: transform 0.5s ease;
            }
        }

        &--text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            @include margin-top(2em);

            &--number {
                font-weight: 700;
                @include margin-bottom(1em);
                @include padding-bottom(1em);
                border-bottom: 1px solid;
                border-color: $color-gray;
                transform: border 0.5s ease;
                display: inline-block;
                text-align: center;
                width: 100%;
            }

            &--title {
                @include font-body();
                margin: 0;
            }
        }
    }
}