@import './variables';
@import '~rfs/scss';

#contact-contact {
    margin: 0;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .contact-contact {

        &-subtitle-1 {
            margin-bottom: 0;
        }

        &-subtitle-2 {
            margin-bottom: 1em;
        }

        &-subtitle {
            display: inline-block;

            &-rollover {
                display: inline-block;
                position: relative;
                margin-left: 0.3em;
                @include font-heading();
                top: 20px;
                width: 55%;
                overflow: hidden;
                min-height: 35%;

                &-1,
                &-2,
                &-3 {
                    position: absolute;
                    top: 0;
                    left: 0;
                    white-space: nowrap
                }
            }
        }

        &-address {
            position: relative;

            &:before {
                content: "";
                margin-right: 1em;
                border-top: 1px solid $color-gray;
                width: 50px;
                position: absolute;
                left: -25%;
                top: 15%;
            }
        }

        &-btn {
            @include margin-top($space * 5);
            color: $color-primary;
        }
    }
}