@import './variables';
@import '~rfs/scss';

#work-nodetail {
    margin: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    .work-nodetail {

        &-bg {
            width: 100%;
            height: 100vh;
            position: fixed;
            top: 0;
            left: 0;
            opacity: 0.3;
            overflow: hidden;

            img {
                width: 100%;
                height: 100vh;
                object-fit: cover;
            }
        }

        &-input {
            display: flex;
            align-items: center;
            justify-content: center;

            &-divider {
                @include font-heading();
                @include margin-left(0.8em);
                @include margin-right(0.8em);
            }
        }
    }
}

.work-detail {
    img {
        display: block;
        margin: auto;
    }

    .text-nocolor {
        color: inherit;
    }
}

.work-detail-bg {
    margin: 0;
    width: 100%;
    overflow: hidden;
    position: relative;
    height: 100vh;

    .parallax {
        height: 100vh;

        .parallax-image {
            height: 150vh;
        }
    }

    img {
        width: 100%;
        object-fit: cover;
        height: 100vh;
    }

    .work-detail-bg {

        &-text {
            position: absolute;
            display: flex;
            align-items: center;
            bottom: 20%;
            left: 18%;
            z-index: 2;
        }

        &-casestudy {
            font-family: $font-serif;
            font-weight: 700;
        }

        &-divider {
            border-top: 1px solid $color-text;
            opacity: 0.6;
            width: 120px;
            margin: 0 3em;
        }

        &-scrolldown {
            text-transform: uppercase;
            font-weight: 700;
        }
    }
}

.work-detail-article {
    h2 {
        @include font-body();
        font-family: $font-sans-serif;
        text-transform: uppercase;
        font-weight: 700;
    }

    h3 {
        @include font-heading();
    }

    .work-detail-article-white {
        color: $color-text;
    }

    &-list-title {
        @include font-body();
        font-family: $font-sans-serif;
        font-weight: 700;
        text-transform: uppercase;
    }

    ul {

        li {
            @include font-small();
        }
    }

    small {
        @include font-small($weight: $font-weight-bold);
        text-transform: uppercase;
    }

    &-slider {
        margin: 5em 0;


        .parallax {
            height: 30vh;
            margin: 2em 0;

            .parallax-image {
                height: 40vh;
            }
        }

        .swiper-button {

            &-wrap {
                margin-top: -4em;
            }

            background: $color-background;
            padding: 3em 4em;
            cursor: pointer;
            position: relative;
            display: inline-block;
            z-index: 2;
            margin: 0 1em;
        }
    }

    &-overview {
        margin-top: 5em;

        span {
            @include font-small($weight: $font-weight-bold);
            text-transform: uppercase;
        }

        h3 {
            @include font-subheading();
            margin-top: 0.5em;
            margin-bottom: 1em;
        }


    }
}

.work-detail-bg-2 {
    margin: 0;
    width: 100%;
    overflow: hidden;
    position: relative;
    height: 120vh;

    .parallax {
        height: 140vh;

        .parallax-image {
            height: 180vh;
        }
    }

    img {
        width: 100%;
        object-fit: cover;
        height: 130vh;
    }

    .work-detail-bg-2 {

        &-text {
            position: absolute;
            z-index: 2;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            bottom: 10%;
            right: 20%;
            max-width: 600px;

            small {
                @include font-small($weight: $font-weight-bold);
                text-transform: uppercase;
                margin-bottom: 1em;
            }

            h3 {
                @include font-heading();
            }
        }
    }
}

#work-detail-gallery {

    img {
        width: 100%;
        object-fit: cover;
    }

    .parallax {
        height: 30vh;
        margin: 5em 0;


        .parallax-image {
            height: 40vh;
        }
    }

    h2 {
        font-family: $font-serif;
        text-transform: none;
    }

    .work-detail-gallery-mobile {
        margin-top: 10em;
    }
}

#work-detail-mobile {
    margin-top: -10em;

    .work-detail-mobile {

        &-center {
            margin-top: -20em;
        }

    }

    img {
        margin-bottom: 2em;
    }
}

#work-detail-related {
    padding: 10em 0;
    color: $color-background;

    a {
        color: $color-background;
    }

    .btn {
        color: $color-text;
    }

    h2 {
        margin-bottom: 3em;
    }

    .work-detail-related {

        &-wrap {
            background: $color-text;
            padding: 5em;
        }

        &-item {
            margin: 2em 0;

            h3 {
                @include font-subheading();
            }

            small {
                color: rgb(124 125 132);
                font-family: $font-serif;
                text-transform: none;
                font-weight: $font-weight-body;
            }

            .number {
                @include font-small();
                color: rgb(124 125 132);
                font-weight: 700;
            }
        }

        &-image {
            position: absolute;
            bottom: 0;
            right: 0;
            max-width: 50%;

            &-img {
                float: right;
                opacity: 0;
                transition: opacity 0.3s ease;

                &:last-child {
                    position: absolute;
                    z-index: 2;
                }
            }

            &-img.active {
                opacity: 1;
            }
        }
    }
}