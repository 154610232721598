// Rest
@import './variables';
// @import '~normalize.css/normalize.css';
@import 'reset-css/reset.css';
@import 'bootstrap/dist/css/bootstrap-grid.min.css';
@import 'swiper/swiper.min.css';
@import './plugin/locomotive-scroll.css';
@import '~rfs/scss';
@import './global';

// Navbar
html[data-direction="down"] #navbar {
    transform: translate3d(0, -100%, 0);
}

html[data-direction="up"] #footer {
    transform: translate3d(0, 100%, 0);
}

#navbar {
    @include padding-top($space * 3);
    @include padding-left($space * 5);
    @include padding-right($space * 5);
    position: fixed;
    width: 100%;
    z-index: 999;
    opacity: 1;
    display: block;
    transition: transform 0.3s ease;

    .navbar-wrap {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    #logo {
        width: 126px;
        height: 50px;
        cursor: pointer;
        pointer-events: all;
        opacity: 1;
        transition: opacity 0.3s ease;
        position: relative;

        &:hover {
            opacity: 0.8;
        }

        .logo-white {
            transition: opacity 2.5s ease;
            opacity: 1;
        }

        .logo-black {
            transition: opacity 2.5s ease;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
        }

        .digitalagency-mobile {
            display: none;
        }
    }

    #menu {

        .menu {

            &-desktop {
                display: block;
            }

            &-mobile {
                display: none;

                &-list {

                    &-item {

                        a {
                            color: $color-blue !important;
                        }
                    }
                }
            }

            &-list.active {
                opacity: 0.5;
                pointer-events: none;
            }

            &-list {
                position: relative;
                @include margin-horizontal($space * 3);
                @include font-body();
                font-family: $font-serif;
            }
        }
    }

    #socmed {
        display: none;

        .socmed {

            &-list {
                &-item {
                    display: inline-block;
                    vertical-align: middle;
                    margin: 0 3px;
                }
            }
        }

        .link-icon {
            color: $color-blue;
            border-color: $color-blue;
            width: 30px;
            height: 30px;
        }
    }

    .digitalagency {
        position: relative;

        &:before {
            content: "";
            position: absolute;
            background: #dedede;
            height: 1px;
            width: 48px;
            left: -72px;
            top: 50%;
        }
    }
}

#navbar-mobile {
    display: none;
}

#slider {
    margin: 0;
    height: 100vh;
    /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: 1;

    .slider {

        &-inner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &-list {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
        }

        &-pagination {
            position: absolute;
            @include margin-top(-$space * 10);
            left: -27em;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: default;
            z-index: 2;
            text-align: center;


            &-item {
                @include font-small();
                position: relative;
                display: inline-block;
                padding: 4em 3em;
                position: relative;
                cursor: pointer;
                z-index: 1;
                opacity: 0.5;
                font-weight: bold;
                border-bottom: 1px solid;
                border-color: $color-text;

                &:hover .slider-pagination-item-icon {
                    opacity: 0.3;
                    transform: translate(0, -7px);
                }

                &-icon {
                    display: inline-block;
                    transition: transform 0.3s ease;
                }

                &.is-active {
                    pointer-events: none;
                    opacity: 1;

                    &:hover .slider-pagination-item-icon {
                        opacity: 1;
                    }
                }
            }
        }

        &-arrows {
            position: absolute;
            @include margin-top(-$space * 18);
            left: -15em;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            z-index: 2;
            text-align: center;

            &-arrow {
                @include padding($space * 1.2);
                width: 8px;
                height: 8px;
                border: 0px solid;
                border-color: transparent;
                border-radius: 100%;
                position: relative;
                transition: border 0.3s ease;
                cursor: pointer;

                &:hover {
                    border: 1px solid $color-text;
                }

                &.prev {
                    @include margin-bottom($space * 1);
                }

                &-icon {
                    position: absolute;
                    left: 0.8em;
                    top: 0.8em;
                }
            }
        }
    }

    .slider-list-item {
        display: none;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0;
        transition: opacity 0.3s ease;

        &.is-active,
        &.is-not-active {
            display: block;
        }

        &.is-loaded {
            opacity: 1;
        }

        &-content {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 3;
            text-align: left;
            display: flex;
            justify-content: center;
            align-items: center;

            &-caption {
                max-width: 768px;
                @include margin-top($space * 15);

                &-category,
                &-btn {
                    position: relative;
                    left: 8em;
                }

                &-category {
                    top: -2.2em;
                    text-align: left;
                }

                &-btn {
                    top: 3em;
                    @include padding-vertical();
                    text-transform: uppercase;
                    font-weight: bold;
                    position: relative;
                    border-bottom: 2px solid;
                    border-color: $color-text;

                    // &:after {
                    //     content: "";
                    //     height: 2px;
                    //     width: 100%;
                    //     background: $color-text;
                    //     position: absolute;
                    //     bottom: 0;
                    //     left: 0;
                    //     transform-origin: left;
                    // }
                }
            }
        }

        video {
            height: 100vh;
            min-height: 100%;
            object-fit: cover;
        }

        &-image,
        &-video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-position: center;
            z-index: 1;
            background-size: cover;
            image-rendering: optimizeQuality;

            // &::before {
            //     content: "";
            //     display: block;
            //     position: absolute;
            //     top: 0;
            //     left: 0;
            //     width: 100%;
            //     height: 100%;
            //     background: rgba(0, 0, 0, 0.5);
            // }

            &-img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transform: scale(1.1);
            }

            &-vid {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

#footer {
    @include padding-top($space * 3);
    @include padding-bottom($space * 3);
    @include padding-left($space * 5);
    @include padding-right($space * 5);
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9999;
    opacity: 0.6;
    @include font-small();
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    transition: transform 0.3s ease;

    .footer {

        &-email {
            &-link {
                display: flex;
                align-items: center;

                &-icon {
                    margin-right: 1.5em;
                }
            }
        }

        &-socmed {
            display: flex;

            span {
                margin: auto;
                margin-right: 20px;
            }

            &-list {
                list-style: none;
                margin: 0;
                padding: 0;

                &-item {
                    margin-right: 10px;
                }
            }
        }
    }
}

.cursor-masking {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
    background: rgb(68, 83, 102);
    opacity: 1;
    width: 400%;
    height: 400%;
    display: block;
    mix-blend-mode: multiply;

    &-mask {
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        width: 80em;
        height: 80em;
        opacity: 1;
        pointer-events: none;
    }
}

.isblack {
    transition: color 2s ease, border 2s ease;
    color: $color-background !important;
    border-color: $color-background !important;
}

.isblack_bg {
    background: $color-background !important;
}

.link-brown {
    color: $color-primary;
}

.link-border {
    position: absolute;
    height: 2px;
    width: 100%;
    background: $color-text;
    bottom: -10px;
    left: 0;
    transform: scaleX(0);
    transform-origin: left;
}

.link-icon {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border: 1px solid;
    border-color: $color-text;
    border-radius: 100%;
    display: inline-block;
    transform-origin: center;
    overflow: hidden;
    cursor: pointer;

    svg {
        vertical-align: middle;
        display: inline-block;
        width: 100%;
        height: 100%;
        padding: 7px;
    }
}

.global-video {
    display: none;
}

// Pages
@import './work';
@import './work_detail';
@import './agency';
@import './contact';

// Responsive
@import './responsive';