@import '~rfs/scss';

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    $font-size-root: 14px;

    :root {
        font-size: $font-size-root;
    }
}

// Small devices (landscape phones, less than 768px)

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {}

@media (min-width: 991.98px) {
    #agency-client {

        .agency-client {

            &-list {

                &-item {
                    margin: initial;
                    @include margin-right($space * 5);
                    @include margin-top($space * 5);
                    padding: 0;
                }
            }
        }
    }

    #agency-approach {

        .agency-approach {

            &-list {
                display: flex;
                justify-content: space-between;

                &-item {
                    display: block;

                    &-icon {
                        width: 130px;
                        height: 130px;
                        border: 1px solid $color-gray;
                        border-radius: 100%;
                        padding: 1.5em;
                        display: flex;
                        margin: auto;
                        @include margin-bottom($space);

                        img {
                            width: 40%;
                            margin: auto;
                        }
                    }

                    &-title {
                        text-align: center;
                        margin: auto;
                        display: block;
                    }
                }
            }
        }
    }

    #agency-awards {

        .agency-awards {

            &-list {

                &-item {
                    width: 83px;
                }
            }
        }
    }

    .carousel {

        &--item {
            width: 30vw;
        }
    }

    .work-detail-article {

        &-slider {

            .parallax {
                height: 70vh;
                margin: 2em 0;

                .parallax-image {
                    height: 80vh;
                }
            }

            .swiper-button {

                &-wrap {
                    margin-top: -4em;
                    margin-left: 5em;
                }

            }
        }
    }

    #work-detail-gallery {

        .parallax {
            height: 80vh;
            margin: 5em 0;

            .parallax-image {
                height: 110vh;
            }
        }
    }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {}

@media (min-width: 1199.98px) {}

@media (max-width: 1399.98px) {
    #slider {

        .slider {

            &-pagination {
                left: -18em;
                margin-top: -10em;
            }

            &-arrows {
                left: -6em;
                margin-top: -16em;
            }
        }

        .slider-list-item {
            &-content {
                // left: 12em;

                &-caption {
                    margin-top: 13em;
                    margin-left: 18em;

                    &-category {
                        top: -1.2em;
                    }

                    &-btn {
                        top: -2em;
                    }
                }
            }
        }
    }
}

// Desktop size
@media (min-width: 1399.98px) {}

@media (max-width: 767.98px) {
    .cursor-masking {
        display: none;
    }

    #navbar {
        background: transparent !important;
        padding-bottom: 0 !important;
    }

    #navbar-mobile {
        display: block;
        z-index: 99999;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        @include padding-top($space * 2);
        @include padding-left($space * 2);

        .logo-white {
            opacity: 1;
        }

        .logo-black {
            opacity: 0;
        }

        .navbar-mobile-wrap {
            position: relative;
        }

        img {
            position: absolute;
            max-width: 94px;
            display: inline-block;
        }

        .digitalagency-mobile {
            color: $color-text;
            position: absolute;
            left: 110px;
            top: $space;

            span {
                @include margin-left($space / 2);
                @include margin-right($space / 2);
            }
        }

        #hamburger {
            position: fixed;
            top: 2em;
            right: 2em;
            display: block;

            .hamburger {
                cursor: pointer;

                &-line {
                    width: 30px;
                    height: 1px;
                    margin: 8px 0;
                    background: $color-text;
                }
            }
        }
    }

    .navbar-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: $color-primary;
        z-index: 999;
        transform-origin: left;
        transform: scaleX(0);
    }

    #navbar {
        opacity: 0;
        display: none;
        height: 100vh;
        width: 100%;
        color: $color-background;
        @include padding-top($space * 4);
        @include padding-bottom($space * 4);
        @include padding-left($space * 4);
        @include padding-right($space * 4);

        a {
            color: $color-cream !important;
        }

        .navbar-wrap {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            position: relative;
            height: 100%;
        }

        #logo {
            display: none;
        }

        #menu {
            color: $color-blue;
            width: 100%;

            .menu {

                &-desktop {
                    display: none;
                }

                &-mobile {
                    display: block;

                    &-list {
                        @include margin-vertical($space *4);

                        &:last-child {
                            position: relative;
                            @include margin-top($space * 6);

                            &:before {
                                content: "";
                                position: absolute;
                                width: 30px;
                                height: 2px;
                                top: -3em;
                                left: 0;
                                background: $color-blue;
                            }
                        }

                        &-item {
                            @include margin-top($space - 0.4);
                            @include margin-bottom($space - 0.4);

                            &:first-child {
                                @include margin-bottom($space);
                                text-transform: uppercase;
                                font-weight: bold;
                            }

                            &-arrow {
                                width: 25px;
                                height: 7px;
                            }
                        }
                    }
                }
            }
        }

        #socmed {
            display: block;
            width: 100%;
        }

        .digitalagency {
            display: none;
        }
    }

    #slider {

        .slider {

            &-pagination {
                display: none;
            }

            &-arrows {
                bottom: 3em;
                padding-right: 3em;
                left: 0;
                align-items: flex-end;

                &-arrow {
                    border: 1px solid;
                    border-color: $color-text;

                    &-icon {
                        top: 0.6em;
                        left: 0.6em;
                    }
                }
            }
        }

        .slider-list-item {
            &-content {
                // left: 12em;

                &-caption {
                    @include padding(2em);
                    margin-top: 10em;
                    margin-left: 0;

                    &-category {
                        left: 0;
                    }

                    &-btn {
                        top: 0;
                        left: 0;
                    }
                }
            }
        }
    }

    #footer {
        display: none;
    }

    .agency {
        background-image: url("../../public/images/static/letter-g.png"), url("../../public/images/static/letter-r.png"), url("../../public/images/static/letter-e.png"), url("../../public/images/static/letter-a.png"), url("../../public/images/static/letter-t.png");
        background-size: contain, contain, contain, contain, contain;
        background-position: center 20%, center 40%, center 60%, center 80%, center 100%;
        background-repeat: no-repeat;
    }

}