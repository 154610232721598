@import './variables';
@import '~rfs/scss';

#video-bg {
    width: 100%;
    height: 100vh;
    min-height: 100%;
    object-fit: cover;
    position: fixed;
    top: 0;
    left: 0;
    background-position: center;
    z-index: -2;
    background-size: cover;
    image-rendering: optimizeQuality;

    .video-bg {

        &-vid {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.cursor-agency {
    position: fixed !important;
    z-index: -1 !important;
}

#agency-intro {
    margin: 0;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .agency-intro {

        &-wrap {
            margin: auto;
            display: block;
        }

        &-desc {

            &-scroll {
                @include margin-top($space * 5);
                display: block;
                text-transform: uppercase;
                font-weight: bold;
                position: relative;
                overflow: hidden;
                min-height: 25px;

                &-text,
                &-text-2 {
                    position: absolute;
                    left: 0;
                    right: 0;
                }
            }
        }
    }
}

#agency-services {

    .agency-services {

        &-desc {
            @include margin-bottom($space * 5);
        }

        &-list {
            @include margin-top($space);

            &-wrap {
                @include margin-top($space);
            }

            &-item {
                color: $color-gray;
                @include font-small();
                @include margin-top($space);
                @include margin-bottom($space);
            }
        }
    }
}

#agency-client {

    .agency-client {

        &-list {
            @include margin-top($space * 5);

            &-item {
                display: inline-block;
                width: 83px;
                padding: 0.8em;
                margin: auto;
            }
        }
    }
}

#agency-approach {

    .agency-approach {

        &-list {
            @include margin-top($space * 5);
            display: block;

            &-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                @include margin-bottom($space * 2);

                &-icon {
                    display: inline-block;
                    @include margin-right($space * 2);

                    img {
                        width: 25px;
                        margin: auto;
                    }
                }

                &-title {
                    @include font-small();
                    font-weight: bold;
                    text-transform: uppercase;
                    text-align: left;
                    display: inline-block;
                }
            }
        }
    }
}

#agency-awards {

    .agency-awards {

        &-subtitle {
            @include margin-bottom($space);
        }

        &-list {
            @include margin-top($space * 3);
            display: flex;
            justify-content: space-between;

            &-item {
                display: inline-block;
                width: 50px;
                margin: auto;
            }
        }
    }
}

#agency-contact {
    padding-bottom: $margin-section * 2;

    .agency-contact {

        &-subtitle-1 {
            margin-bottom: 0;
        }

        &-subtitle-2 {
            margin-bottom: 1em;
        }

        &-subtitle {
            display: inline-block;

            &-rollover {
                display: inline-block;
                position: relative;
                margin-left: 0.3em;
                @include font-heading();
                top: 20px;
                width: 55%;
                overflow: hidden;
                min-height: 20%;

                &-1,
                &-2,
                &-3 {
                    position: absolute;
                    top: 0;
                    left: 0;
                    white-space: nowrap
                }
            }
        }

        &-btn {
            @include margin-top($space * 5);
            color: $color-primary;
        }
    }
}

.page-title {
    color: $color-primary;
    @include font-body($weight: $font-weight-bold);
    font-family: $font-sans-serif;
    text-transform: uppercase;
}

.page-title-small {
    @include font-body($weight: $font-weight-bold);
    font-family: $font-sans-serif;
    text-transform: uppercase;
}

.page-subtitle {
    @include font-heading();
    @include margin-bottom($space * 2);
}

.page-subtitle2 {
    @include margin-bottom($space);
}

.page-line {
    border-top: 1px solid $color-gray;
    width: 100%;
}