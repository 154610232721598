@import './variables';
@import '~rfs/scss';

@font-face {
    font-family: "didonesque";
    src: url(../../public/fonts/didonesque-roman-webfont.woff2);
    font-weight: normal;
}

@font-face {
    font-family: "opensans";
    src: url(../../public/fonts/opensans-regular-webfont.woff2);
    font-weight: normal;
}

@font-face {
    font-family: "opensans";
    src: url(../../public/fonts/opensans-light-webfont.woff2);
    font-weight: lighter;
}

@font-face {
    font-family: "opensans";
    src: url(../../public/fonts/opensans-bold-webfont.woff2);
    font-weight: bold;
}

* {
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -o-font-smoothing: antialiased;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-overflow-scrolling: touch;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: none;
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
    text-size-adjust: 100%;
}

:root {
    font-size: $font-size-root;
}

html {
    height: -webkit-fill-available;
}

body {
    @include font-body();
    background: $color-background;
    color: $color-text;
    overflow-x: hidden;
    font-family: $font-sans-serif;
    min-height: -webkit-fill-available;
}

section {
    margin: $space * 15 0;
}

a {
    color: $color-text;
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
}

h2 {
    @include font-heading();
}

h2,
h3 {
    @include margin-bottom($margin-heading);
}

h3,
h4,
h5,
h6 {
    @include font-subheading();
}

p {
    margin: 0;
    color: $color-gray;
}

small {
    @include font-size($font-size-small);
}

img {
    max-width: 100%;
}

input {
    position: relative;
    border-top: none;
    border-right: none;
    border-bottom: 1px solid rgb(255, 255, 255);
    border-left: none;
    border-image: initial;
    background: none;
    color: $color-text;
    border-radius: 0px;
    padding: 6px 0px 4px;
    display: inline-block;
    text-align: left;
    width: 200px;
    font-size: 14px;
    line-height: 14px;

    &::placeholder {
        color: $color-primary;
        font-weight: 700;
    }

    &:focus {
        outline: none;
    }
}

// Global style

#loading {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: $color-background;
    color: $color-text;
    z-index: 99999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform-origin: left;

    .loading {

        &-overlay {
            width: 0%;
            height: 100%;
            position: absolute;
            background: $color-primary;
            transform-origin: left;
            right: 0;
        }

        &-text {
            @include font-heading();
            text-align: center;
        }

        &-desc {
            text-align: center;
            position: absolute;
            bottom: 5em;

            &-tagline {
                display: block;
                opacity: 0.4;
                margin-bottom: 7px;
            }

            &-title {
                display: block;
            }
        }

        &-logo {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            justify-content: space-between;
            align-items: center;
            display: none;

            img {
                display: inline-block;
                height: 65px;
                width: 30px;
                object-fit: contain;
            }

            &-small {
                position: absolute;
                top: 3.5em;
                right: 0;
            }
        }
    }
}

.container {
    position: relative;
}

.border-left {
    border-left: 1px solid $color-text;
    height: 100%;
    position: absolute;
    left: 15px;
    top: 0;
    opacity: 0.3;
}

.border {
    width: 100%;
    border-bottom: 1px solid $color-text;
}

.masking-text {
    overflow: hidden;
    display: block;

    .line {
        position: relative;
    }
}

.line {
    display: inline-block !important;
}

.reveal-image {
    position: relative;
    overflow: hidden;
    visibility: hidden;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        transform-origin: left;
    }
}

.parallax {
    width: 100%;
    height: 60vh;
    overflow: hidden;

    .parallax-image {
        width: 100%;
        height: 100vh;
        object-fit: cover;
    }
}


#canvas {
    /* make the canvas wrapper fits the document */
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.plane {
    width: 100%;
    height: 50vh;

    img {
        display: none;
    }
}

.magnet {
    display: inline-block;
}

.btn {
    @include font-small($weight: $font-weight-bold);
    @include padding-vertical();
    text-transform: uppercase;
    border-bottom: 2px solid;
    border-color: $color-text;
    cursor: pointer;
}