@import '~rfs/scss';

$color-primary: #FEE8E2;
$color-background: #13161F;
$color-text: #FFFFFF;
$color-blue: #2b3958;
$color-gray: rgba(255, 255, 255, 0.466);
$color-cream: #b47978;
$font-size-root: 18px; // (also) font-size-body

$font-serif: "didonesque", serif;
$font-sans-serif: "opensans", sans-serif;
// Font size
$font-size-big: 6.8rem; // (special) used in #studio section
$font-size-heading: 2.9rem;
$font-size-subheading: 1.4rem;
$font-size-body: 1rem;
$font-size-small: 0.8rem;

// Font weight
$font-weight-black: 900;
$font-weight-bold: 700;
$font-weight-heading: 400;
$font-weight-subheading: 400;
$font-weight-body: 400;
$font-weight-small: 300;

// Font letter spacing
$font-spacing-heading: -0.04em;
$font-spacing-subheading: 0;
$font-spacing-body: 0;
$font-spacing-small: 0;

$margin-section: 7em;
$margin-heading: 0.8em;

$space: 1em;

@mixin font-body($weight: $font-weight-body, $size: $font-size-body, $spacing: $font-spacing-body){
    @include font-size($size);
    font-style: normal;
    font-weight: $weight;
    letter-spacing: $spacing;
    line-height: 1.4;
}

@mixin font-heading($weight: $font-weight-heading, $size: $font-size-heading, $spacing: $font-spacing-heading){
    @include font-size($size);
    font-family: $font-serif;
    font-weight: $weight;
    letter-spacing: $spacing;
    line-height: 1.3;
}

@mixin font-subheading($weight: $font-weight-subheading, $size: $font-size-subheading, $spacing: $font-spacing-subheading){
    @include font-size($size);
    font-family: $font-serif;
    font-weight: $weight;
    letter-spacing: $spacing;
    line-height: 1.1;
}

@mixin font-small($weight: $font-weight-small, $size: $font-size-small, $spacing: $font-spacing-small){
    @include font-size($size);
    font-weight: $weight;
    letter-spacing: $spacing;
}

%border-heading {
    position: relative;
    &:before {
        content: "";
        position: absolute;
        left: 11px;
        top: -30px;
        width: 75px;
        border-top: 4px solid $color-text;
    }
}

@mixin margin-horizontal($size: $space){
    @include margin-left($size);
    @include margin-right($size);
}

@mixin margin-vertical($size: $space){
    @include margin-top($size);
    @include margin-bottom($size);
}

@mixin padding-horizontal($size: $space){
    @include padding-left($size);
    @include padding-right($size);
}

@mixin padding-vertical($size: $space){
    @include padding-top($size);
    @include padding-bottom($size);
}